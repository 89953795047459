import clone from 'lodash/clone';
import { useMemo } from 'react';

import { Tag, TagProps } from '@/modules/shared/components/Atom/Tag';
import { getActiveTags, onTagChange } from '@/modules/utils';

export type SortGroupProps = {
  list: TagProps[];
  onChange: (list: TagProps[]) => void;
  className?: string;
  displayAll?: boolean;
};

export const SortGroup = ({
  list,
  displayAll = false,
  onChange,
  className,
}: SortGroupProps) => {
  const activeTags = getActiveTags(list);
  const all = useMemo(() => activeTags.length === 0, [activeTags]);
  const selectAll = () => {
    if (!activeTags.length) return;

    onChange?.(
      clone(list).map((item) => {
        item.active = false;

        return item;
      }),
    );
  };

  return (
    <>
      {displayAll && (
        <Tag
          slug={Math.random().toString()}
          label={'All'}
          active={all}
          onClick={selectAll}
        />
      )}

      {list.map((item, index) => (
        <Tag
          key={index}
          slug={item.slug}
          label={item.label}
          disabled={item.disabled}
          top={item.top}
          active={item.active}
          onClick={() => onTagChange(item, list, onChange)}
        />
      ))}
    </>
  );
};
