import { TagProps } from '@/modules/shared/components/Atom/Tag';

export const getActiveTags = (list: TagProps[]): string[] => {
  return list.filter((tag) => tag.active).map((tag) => tag.slug);
};

export const getActiveTagsObject = (list: TagProps[]) => {
  return list.filter((tag) => tag.active);
};

export const onTagChange = (
  selectedTag: TagProps,
  tags: TagProps[],
  selectTags: (updatedTags: TagProps[]) => void,
) => {
  const updatedTags = tags.map((tag) => {
    if (tag.slug === selectedTag.slug) {
      return {
        ...tag,
        active: !tag.active,
      };
    }
    return tag;
  });

  selectTags(updatedTags);
};
