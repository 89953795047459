import { ListQuests } from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'next-i18next';

import { QuestsCategorizedList } from '@/modules/quests/components/QuestsCategorizedList/QuestsCategorizedList';

export type CommunityQuestsProps = {
  communityId: string;
};

export const CommunityQuests = ({ communityId }: CommunityQuestsProps) => {
  const { t } = useTranslation(['errors']);

  return (
    <div className="communityview__quests">
      <ListQuests communityId={communityId}>
        {({
          data,
          status,
          filters,
          filtersStatus,
          filterOnChange,
          refetch,
        }) => (
          <QuestsCategorizedList
            list={data}
            status={status}
            filters={filters}
            filtersStatus={filtersStatus}
            onReload={refetch}
            onTagChange={filterOnChange}
            rejectedMessage={t('errors:globalErrors.quests_list_rejected')}
            emptyMessage={t('errors:globalErrors.quests_list_empty')}
          />
        )}
      </ListQuests>
    </div>
  );
};
