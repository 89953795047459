import { HTMLAttributes } from 'react';

import { Icon } from '@/modules/shared/components/Atom/Icon';

import classes from './Chip.module.scss';
export function Chip({
  text,
  variant = 'transparent',
  onClose,
  uniqueId,
  ...rest
}: Omit<HTMLAttributes<HTMLDivElement>, 'children'> & {
  text: string;
  uniqueId: string;
  variant?: 'transparent' | 'solid';
  onClose?: (text: string) => void;
}) {
  const { onClick, className, ...props } = rest;
  return (
    <div
      onClick={onClick}
      className={`${classes.chip} ${classes[`variant_${variant}`]} ${
        className ?? ''
      }`}
      {...props}
    >
      <span onClick={() => onClose?.(uniqueId)}>
        <Icon.close size={12} className={classes.chip_icon} />
      </span>
      <span className={classes.chip_text}>{text}</span>
    </div>
  );
}
