import {
  CommunityQuestTag,
  QUEST_STATUS,
  QuestDetailsType,
} from '@xborglabs/ui-shared';
import orderBy from 'lodash/orderBy';

import { SingleQuest } from '@/modules/quests/components/SingleQuest/SingleQuest';
import { Card } from '@/modules/shared/components/Atom/Card/Card';
import { Progressbar } from '@/modules/shared/components/Atom/Progressbar';
import classes from './QuestCategoryContainer.module.scss';

type QuestCategoryContainerProps = {
  label: string;
  list: QuestDetailsType[];
  slug: string;
  filters?: CommunityQuestTag[];
};

function QuestCategoryContainer({
  label,
  list,
  filters,
  slug,
}: QuestCategoryContainerProps) {
  return (
    <Card className="category-container">
      <div className={classes.label_container}>
        <QuestCategoryLabel label={label} />
        <QuestProgress filters={filters} slug={slug} />
      </div>

      <QuestCategoryContent list={list} />
    </Card>
  );
}

export default QuestCategoryContainer;

function QuestCategoryContent({ list }: { list: QuestDetailsType[] }) {
  const sorted = orderBy(
    list,
    (l) => [QUEST_STATUS.ACTIVE, QUEST_STATUS.RESOLVING].includes(l.status),
    'desc',
  );
  return (
    <div className="category-quests">
      {sorted.map((q: QuestDetailsType) => (
        <SingleQuest data={q} key={q.questId} />
      ))}
    </div>
  );
}
function QuestCategoryLabel({ label }: { label: string }) {
  return (
    <div className="flex">
      <div className="category-name font-bold">{label}</div>
    </div>
  );
}
function QuestProgress({
  filters,
  slug,
}: {
  slug: string;
  filters?: CommunityQuestTag[];
}) {
  const filter = filters?.find((f) => f.slug === slug);

  const completedQuestsCount = filter?.completedQuests ?? 0;
  const availableQuests = filter?.totalQuests ?? 0;
  const totalQuestsCount = availableQuests + completedQuestsCount;

  const progress = Math.round((completedQuestsCount / totalQuestsCount) * 100);

  return (
    <div className={classes.progress}>
      <Progressbar
        progress={progress}
        color="community"
        size="small"
        label={
          <span>
            {completedQuestsCount}/{totalQuestsCount}
          </span>
        }
      />
    </div>
  );
}
