import { QueryStatus } from '@tanstack/react-query';
import { AnimatePresence, m } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { P, match } from 'ts-pattern';

import { BUTTON_SIZE, Button } from '@/modules/shared/components/Atom/Buttons';
import { Card, CardProps } from '@/modules/shared/components/Atom/Card/Card';
import { Chip } from '@/modules/shared/components/Atom/Chip/Chip';
import { Icon } from '@/modules/shared/components/Atom/Icon';
import { TagProps } from '@/modules/shared/components/Atom/Tag';
import { SortGroup } from '@/modules/shared/components/Organism/SortGroup';
import { Shimmer } from '@/modules/shared/components/Template/Loader/Shimmer';
import { getActiveTags, getActiveTagsObject } from '@/modules/utils';

import classes from './QuestsFilters.module.scss';

export type QuestFiltersProps = {
  filters: TagProps[];
  status: QueryStatus;
  onChange: (list: string[]) => void;
  variant?: CardProps['variant'];
};

export const QuestsFilters = ({
  variant,
  filters,
  status,
  onChange,
}: QuestFiltersProps) => {
  if (filters.length === 0) return null;
  return (
    <div>
      <QuestsFiltersMobile
        filters={filters}
        status={status}
        onChange={onChange}
      />
      <Card variant={variant} className={classes.desktop_options}>
        <FilterOptions filters={filters} status={status} onChange={onChange} />
      </Card>
    </div>
  );
};

function QuestsFiltersMobile({ filters, status, onChange }: QuestFiltersProps) {
  const { t } = useTranslation(['quests']);
  const [optionsOpen, setOptionsOpen] = useState(false);

  const activeFilters = getActiveTagsObject(filters);
  const activeFiltersCount = activeFilters.length;
  return (
    <>
      <div className={classes.mobile_filter_container}>
        <Card
          className={classes.filterBar}
          onClick={() => setOptionsOpen((open) => !open)}
        >
          <p className={classes.filter_label}>{t('quests:filterQuests')}</p>
          <div className={classes.filtersCount}>
            {!!activeFiltersCount &&
              t('quests:activeFilterCount', {
                count: activeFiltersCount,
              })}
            <Icon.filter size={16} color="white" />
          </div>
        </Card>

        <div className={classes.active_filters}>
          {activeFilters.map((activeFilter) => (
            <Chip
              key={activeFilter.slug}
              onClose={(slug) => {
                onChange(
                  activeFilters
                    .filter((filter) => filter.slug !== slug)
                    .map((f) => f.slug),
                );
              }}
              text={activeFilter.label}
              uniqueId={activeFilter.slug}
            />
          ))}
        </div>
      </div>

      <AnimatePresence>
        {optionsOpen && (
          <m.div
            initial={{ translateY: '90vh' }}
            animate={{ translateY: 0 }}
            exit={{ translateY: '90vh' }}
            transition={{
              bounce: 0,
              duration: 0.2,
            }}
            className={classes.mobile_filter_options_container}
          >
            <div
              className={classes.filter_header}
              onClick={() => setOptionsOpen(false)}
            >
              <div className={classes.close} />
              <p>
                {t('quests:filterQuests')} ({activeFiltersCount})
              </p>
            </div>

            <Card className={classes.mobile_options}>
              <FilterOptions
                filters={filters}
                status={status}
                onChange={onChange}
              />
            </Card>

            <Button
              size={BUTTON_SIZE.LARGE}
              onClick={() => setOptionsOpen(false)}
              className={classes.apply_button}
            >
              {t('quests:applyCount', {
                count: activeFiltersCount,
              })}
            </Button>
          </m.div>
        )}
      </AnimatePresence>
    </>
  );
}

function FilterOptions({ filters, status, onChange }: QuestFiltersProps) {
  return (
    <>
      {match([status, filters])
        .with(['loading', P.select()], () =>
          Array.from(Array(10)).map(() => <Shimmer height={42} />),
        )
        .with(['success', P.when((l) => l.length > 0)], () => (
          <SortGroup
            list={filters}
            onChange={(tags) => {
              onChange?.(getActiveTags(tags));
            }}
            displayAll
          />
        ))
        .otherwise(() => null)}
    </>
  );
}
